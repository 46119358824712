*{
  background-color: #40b3ae;
  color: white;
}
.infos >*,.infos{
  background-color: #006557 ;

}
.infos{
  padding: 20px;
  border-radius: 30px;
  text-align: center;
  margin-top: 20px;
}